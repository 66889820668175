import 'lazysizes'

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import Swup from 'swup'
import containerQueryPolyfill from 'container-query-polyfill'
import Plyr from 'plyr'

window.Alpine = Alpine

const players = document.querySelectorAll('.player');

players.forEach(player => {
  new Plyr(player, {
    controls: ['play-large', 'play', 'progress', 'fullscreen']
  });
});

// Alpine Plugins
Alpine.plugin(collapse)
Alpine.plugin(focus)

Alpine.start()

const swup = new Swup({
  containers: ['#swup', '#swup-page-header', '#swup-page-footer'],
})
